import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/core'

export default function SubmitButton (props) {
  const { children, ...rest } = props

  return (
    <Button
      textTransform="uppercase"
      type="submit"
      fontSize="1em"
      variant="solid"
      size="lg"
      variantColor="primary"
      {...rest}>
      {children}
    </Button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node
}
