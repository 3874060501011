import React from 'react'
import PropTypes from 'prop-types'
import WorkflowBuilder from './WorkflowBuilder'
import { WorkflowBuilderContext } from 'contexts/WorkflowBuilderContext'

const definition = {
  nodes: [
    {
      id: '0',
      label: 'Scan for tests',
      meta: {
        description: 'run a scan on the test directory'
      },
      styles: {
        shape: {
          styles: { fill: '#fff', stroke: '#000', strokeWidth: '0' }
        },
        node: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        },
        label: {}
      }
      // labelType: 'foreign'
    },
    {
      id: '2',
      label: 'Add new tests',
      meta: {
        description: 'add the new test cases to the database'
      },
      styles: {
        shape: {
          styles: { fill: '#fff', stroke: '#000', strokeWidth: '0' }
        },
        node: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        },
        label: {}
      }
      // labelType: 'foreign'
    }
  ],
  edges: [
    {
      from: '0',
      to: '2',
      label: 'Execute in memory',
      // labelType: 'foreign',
      meta: {
        description: 'I have no idea what to say about this so here is some text'
      }
    }
  ]
}

const basic = {
  nodes: [
    {
      id: 'start',
      label: 'Project Start',
      meta: {
        type: 'start',
        name: 'startHere'
      },
      styles: {
        shape: {
          styles: {
            fill: '#ccc',
            stroke: '#000',
            fillOpacity: 1
          }
        },
        node: {
          padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
          }
        },
        label: {
          styles: { pointerEvents: 'none' }
        }
      }
    },
    {
      id: '2',
      label: 'Project End',
      styles: {
        shape: {
          styles: {
            fill: '#fff',
            stroke: '#000',
            fillOpacity: 1
          }
        },
        node: {
          padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
          }
        },
        label: {
          styles: { pointerEvents: 'none' }
        }
      }
    },
    {
      id: '3',
      label: 'A',
      styles: {
        shape: {
          styles: {
            fill: '#fff',
            stroke: '#000',
            fillOpacity: 1
          }
        },
        node: {
          padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
          }
        },
        label: {
          styles: { pointerEvents: 'none' }
        }
      }
    },
    {
      id: '4',
      label: 'B',

      styles: {
        shape: {
          styles: {
            fill: '#fff',
            stroke: '#000',
            fillOpacity: 1
          }
        },
        node: {
          padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
          }
        },
        label: {
          styles: { pointerEvents: 'none' }
        }
      }
    }
  ],
  edges: [
    {
      from: 'start',
      to: '3',
      label: 'Execute in memory',
      // labelType: 'foreign',
      meta: {
        description: 'I have no idea what to say about this so here is some text'
      }
    },
    {
      from: 'start',
      to: '4',
      styles: {
        edge: {
          styles: {
            strokeWidth: '4px',
            fill: '#ffffffff',
            stroke: '#00008a'
          }
        }
      }
      // labelType: 'foreign'
    },
    {
      from: '3',
      to: '2',
      // width: 10,
      // labelType: 'foreign',
      styles: {
        edge: {
          styles: {
            strokeWidth: '4px',
            fill: '#ffffffff',
            stroke: '#00008a'
          }
        },
        marker: {
          styles: {
            strokeWidth: '0',
            fill: '#00008a'
          }
        }
      }
      // markerType: 'circle'
    },
    {
      from: '4',
      to: '2'
      // labelType: 'foreign'
    }
  ]
}

// const workflow = {
//   startHere: {
//     type: 'start',
//     parameters: {
//       next: 'waitHere'
//     }
//   },
//   waitHere: {
//     type: 'wait',
//     parameters: {
//       dealy: 15,
//       next: 'splitThis'
//     }
//   },
//   splitThis: {
//     type: 'split',
//     parameters: {
//       items: ['echoThat', 'echoThis']
//     }
//   },
//   echoThis: {
//     type: 'echo',
//     parameters: {
//       payload: 'foo'
//     }
//   },
//   echoThat: {
//     type: 'echo',
//     parameters: {
//       payload: 'bar'
//     }
//   }
// }

function WorkflowEditorPage () {
  return (
    <WorkflowBuilderContext definition={basic}>
      <WorkflowBuilder/>
    </WorkflowBuilderContext>
  )
}

WorkflowEditorPage.propTypes = {

}

export default WorkflowEditorPage
