import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import { v1 as uuid } from 'uuid'
import CodeEditor from 'elements/Code/CodeEditor'
import WorkflowModalEditor from './WorkflowModalEditor'
import { Node, Edge, ValueCache, Rect, NodeTextLabel, DagreReact, NodeOptions, EdgeOptions, RecursivePartial, ReportSize, Size } from 'dagre-reactjs'
import {
  Box,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Icon,
  IconButton,
  ButtonGroup,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/core'
import { useStateValue } from 'contexts/WorkflowBuilderContext'
import {
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_REMOVE
} from 'actions/workflowBuilderActions'
import start from 'form/workflow/start.json'

function EdgeModal (props) {
  const { isOpen, onClose, clickPos } = props
  const { x, y } = clickPos
  const posX = x > x - 150
  return (

    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent rounded={5} w={200} left={x - 150} top={y - 50} position="absolute" bg="white">
        {/* <ModalHeader>Meny</ModalHeader> */}
        {/* <ModalCloseButton /> */}

        <ModalBody>
          <Stack spacing={2}>
            <Button variant="ghost">Secondary Action</Button>
            <Button variant="ghost">Secondary Action</Button>
          </Stack>
        </ModalBody>

      </ModalContent>
    </Modal>

  )
}
const DEFAULT_EDGE_CONFIG = {
  styles: {
    edge: {
      styles: { fillOpacity: 0, stroke: '#000', strokeWidth: '4px' }
    }
  }
}

const DEFAULT_NODE_CONFIG = {
  styles: {

    node: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10
      }
    },
    shape: {},
    label: {}
  }
}

const generateEdge = (from, to) => {
  return {
    from,
    to,
    label: 'Execute in memory',
    labelType: 'foreign',
    meta: {
      description: 'I have no idea what to say about this so here is some text'
    }

  }
}
const generateNode = (name, meta) => {
  return {
    id: name,
    label: name,
    meta,
    styles: {
      shape: {
        styles: {
          fill: '#ccc',
          stroke: '#000',
          fillOpacity: 1
        }
      },
      node: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }
      },
      label: {
        styles: { pointerEvents: 'none' }
      }
    }
  }
}

export const definitionToBuilder = (definition) => {
  const nodes = []
  const edges = []

  for (const action in definition) {
    const meta = definition[action]
    nodes.push(generateNode(action, meta))
    // Most actions might have 'next'. Some like split use 'items'
    const { parameters } = meta
    const { next, items } = parameters

    const destinations = next ? [next] : items || []

    for (const d of destinations) { edges.push(generateEdge(action, d)) }
  }
  return { nodes, edges }
}

export const builderToDefinition = (schema) => {

}

// Custom label - todo only use meta
export const TextLabel = ({ node }) => {
  return (
    <text className={node.styles.label.className} style={node.styles.label.styles || {}}>
      <tspan xmlSpace="preserve" dy="1em" x="1">
        {node.meta.name || node.label}
      </tspan>
    </text>
  )
}

export const ForeignLabel = ({ edgeMeta }) => {
  return (

    // <div
    //   onClick={() => console.log('sd')}
    //   style={{ width: '20px', height: '20px', borderRadius: '10px', backgroundColor: 'red', border: '1px solid red' }}
    // >
    <IconButton isRound="true" size="sm" icon="gear" />
  /* <div style={{ fontWeight: 'bold' }}>{edgeMeta.label}</div> */
    //   <div onClick={() => console.log('sd')} style={{ fontSize: '10px' }}>{edgeMeta.meta.description}</div>
    // </div>
  )
}

export const CustomButtonLabel = ({ title, description, onButtonClicked }) => {
  return (
    <div style={{ padding: '10px', border: '2px solid #000', width: '180px' }}>
      <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
        { title }
      </div>
      <div>
        { description }
      </div>
      <div>
        <button onClick={onButtonClicked}>Details</button>
      </div>
    </div>
  )
}

export const Foreign = ({ node }) => {
  return (
    <div
      style={{
        borderRadius: '5px',
        borderWidth: '2px 2px 2px 10px',
        borderColor: '#000',
        borderStyle: 'solid',
        maxWidth: '200px',
        minWidth: '180px',
        padding: '10px 10px',
        backgroundColor: '#868686'
      }}
    >
      <div style={{ fontWeight: 'bold' }}>{node.label}</div>
      <div style={{ fontSize: '10px' }}>{node.meta.description}</div>
    </div>
  )
}

export const CircleMarker = ({ edgeMeta, markerId }) => {
  return (
    <marker id={markerId} markerWidth="14" markerHeight="14" refX="5" refY="5">
      <circle cx="5" cy="5" r="3" style={edgeMeta.styles.marker.styles} />
    </marker>
  )
}

export default function WorkflowBuilder (props) {
  // const { definition } = props

  // console.log(definitionToBuilder(workflow))
  const [schema, setSchema] = useState()

  const [state, dispatch] = useStateValue()

  // const [status, setStatus] = useState({
  //   highlightedNode: undefined,
  //   selectedNode: undefined
  //   // stage: 0
  // })

  const [stage, setStage] = useState(0)
  const [highlightedNode, setHighlightedNode] = useState()
  const [selectedNode, setSelectedNode] = useState()

  const [isPopOpen, setIsPopOpen] = useState(false)
  const open = () => setIsPopOpen(!isOpen)
  const close = () => setIsPopOpen(false)

  const setHighlighted = useCallback(
    (node) => {
      setHighlightedNode(node)
      setStage(stage + 1)
    },
    [stage]
  )
  const { isOpen: isEdgeMenuOpen, onOpen: onEdgeMenuOpen, onClose: onEdgeMenuClose } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const onDone = useCallback(
    (value) => {
      onClose()
      console.log(value)
    },
    [onClose]
  )

  const [clickPos, setClickPos] = useState({ x: 0, y: 0 })
  const openEdgeModal = useCallback(
    (e) => {
      const { clientX, clientY } = e

      setClickPos({ x: clientX, y: clientY })
      //  console.log(e.)
      open()
      // onEdgeMenuOpen()
    },
    [open]
  )

  // const customPathGenerators = {
  //   d3curve: generatePathD3Curve
  // }
  const customMarkerComponents = {
    circle: CircleMarker
  }

  const customNodeLabels = {
    foreign: {
      renderer: Foreign,
      html: true
    }
  }
  const customEdgeLabels = {
    foreign: {
      renderer: ForeignLabel,
      html: true
    }
  }

  // useEffect(() => {
  //   setSchema(definitionToBuilder(definition))
  //   return () => {

  //   }
  // }, [definition])

  const onChange = useCallback(
    ({ id, values }) => {
      console.log(id)
      console.log(values)

      // console.log(schema)
      dispatch({ type: id ? ACTION_UPDATE : ACTION_CREATE, values, id })
      setStage(stage + 1)
      // setStatus({
      //   ...status,
      //   stage: status.stage + 1
      // })
      // dispatch({ type: UPDATE, values, id })
    },
    [dispatch, stage]
  )
  //  const doDelete = useCallback(

  //     () => {
  //       const { path } = getById(id, state)
  //       dispatch({ type: REMOVE, id, parent: root, path })
  //     },
  //     [dispatch, id, root, state]
  //   )

  // const doSubmit = useCallback(
  //     (values) => {
  //       const { id, path, parent } = editor

  //       dispatch({ type: id ? UPDATE : CREATE, values, path, id, parent })
  //     },
  //     [dispatch, editor]
  //   )

  const openForm = useCallback(
    (node) => {
      setSelectedNode(node)
      onOpen()
    },
    [onOpen]
  )
  if (!state) return null

  const { nodes, edges } = state
  // const { highlightedNode, selectedNode } = status

  const getMarkerComponent = (type, markerComponents) => {
    if (markerComponents[type]) {
      return markerComponents[type]
    }

    throw Error(`marker component ('${type}') not found in builtins or custom`)
  }

  const renderEdge = (index, edgeMeta) => {
    // const markerComponent = getMarkerComponent(
    //   edgeMeta.markerType,
    //   this.state.markerComponents
    // )

    return (
      <g
        key={index}
        onClick={(e) =>
          openEdgeModal(e)
        }
        // onMouseEnter={() =>
        //   setHighlighted(node.id)

        // }
        // onMouseLeave={() =>
        //   setHighlighted()
        // }
      >

        <Edge
          key={`${edgeMeta.from}-${edgeMeta.to}`}
          index={index}
          edgeMeta={edgeMeta}
          markerComponent={CircleMarker}
        />
      </g>

    )
  }

  const renderNode = (
    node,
    reportSize,
    valueCache
  ) => {
    return (
      <Node
        key={node.id}
        node={node}
        reportSize={reportSize}
        valueCache={valueCache}
        html={false}
      >
        {{
        // eslint-disable-next-line react/display-name
          shape: (innerSize) => {
            if (node.id === highlightedNode) {
              node.styles.shape.styles = {
                ...node.styles.shape.styles,
                fill: '#00008a'
              }
              node.styles.label.styles = {
                ...node.styles.label.styles,
                fill: '#fff'
              }
            }
            return (
              <g
                onClick={() =>
                  openForm(node)
                }
                onMouseEnter={() =>
                  setHighlighted(node.id)

                }
                onMouseLeave={() =>
                  setHighlighted()
                }
              >
                <Rect node={node} innerSize={innerSize} />
              </g>
            )
          },
          // eslint-disable-next-line react/display-name
          label: () => <TextLabel node={node} />
        }}
      </Node>
    )
  }
  return (
    <Box m={4}>
      { selectedNode ? <WorkflowModalEditor state={state} formSchema={start} selectedNode={selectedNode} onDone={onDone} onClose={onClose} isOpen={isOpen} onChange={onChange}/> : null }
      <Popover
        returnFocusOnClose={false}
        isOpen={isPopOpen}
        onClose={close}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button variantColor="pink">Popover Target</Button>
        </PopoverTrigger>
        <PopoverContent zIndex={4}>
          <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Are you sure you want to continue with your action?
          </PopoverBody>
          <PopoverFooter d="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button variant="outline">Cancel</Button>
              <Button variantColor="red">Apply</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      <EdgeModal clickPos={clickPos} isOpen={isEdgeMenuOpen} onClose={onEdgeMenuClose}/>
      <svg id="schema" width={1000} height={1000}>
        <DagreReact
          nodes={nodes}
          edges={edges}
          renderNode={renderNode}
          renderEdge={renderEdge}
          stage={stage}
          // customNodeLabels={customNodeLabels}
          customEdgeLabels={customEdgeLabels}
          //= {customPathGenerators}
          customMarkerComponents={customMarkerComponents}
          defaultEdgeConfig={DEFAULT_EDGE_CONFIG}
          defaultNodeConfig={DEFAULT_NODE_CONFIG}
          graphOptions={{
            marginx: 15,
            marginy: 15,
            rankdir: 'LR',
            ranksep: 55,
            nodesep: 15
          }}
        />
      </svg>
    </Box>
  )
}

WorkflowBuilder.defaultProps = {
  // definition: {}
}
WorkflowBuilder.propTypes = {
  // definition: PropTypes.object
}
