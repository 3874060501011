
import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import reducer from 'reducers/workflowBuilderReducer'
import { useImmerReducer } from 'use-immer'

export const WorkflowBuilderActionContext = createContext({
  options: {},
  setOptions: () => {},
  schema: {},
  setSchema: () => {}
})

export const WorkflowBuilderActionProvider = WorkflowBuilderActionContext.Provider
export const WorkflowBuilderActionConsumer = WorkflowBuilderActionContext.Consumer
export const WorkflowBuilderStateContext = createContext()
export const WorkflowBuilderStateProvider = ({ reducer, initialState, children }) => (
  <WorkflowBuilderStateContext.Provider value={useImmerReducer(reducer, initialState)}>
    {children}
  </WorkflowBuilderStateContext.Provider>
)
WorkflowBuilderStateProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
  reducer: PropTypes.func
}

export const WorkflowBuilderStateConsumer = WorkflowBuilderStateContext.WorkflowBuilderStateConsumer

export const useStateValue = () => useContext(WorkflowBuilderStateContext)

// export const useStateValue = () => useImmerReducer(reducer, initialState)

export function WorkflowBuilderContext (props) {
  const [editor, setEditor] = useState(null)
  const [defaultElementComponent, setDefaultElementComponent] = useState()
  const [defaultLevelComponent, setDefaultLevelComponent] = useState({})
  const [defaultEditorComponent, setDefaultEditorComponent] = useState({})
  const [options, setOptions] = useState(props.options)
  const [schema, setSchema] = useState(props.schema)

  return (

    <WorkflowBuilderActionProvider value={{
      editor,
      setEditor,
      options,
      setOptions,
      schema,
      setSchema,
      setDefaultElementComponent,
      setDefaultEditorComponent,
      setDefaultLevelComponent,
      defaultLevelComponent,
      defaultEditorComponent,
      defaultElementComponent
    }}>
      <WorkflowBuilderStateProvider initialState={props.definition} reducer={reducer}>
        {props.children}
      </WorkflowBuilderStateProvider>
    </WorkflowBuilderActionProvider>

  )
}

WorkflowBuilderContext.propTypes = {
  children: PropTypes.node,
  definition: PropTypes.object,
  schema: PropTypes.object,
  options: PropTypes.object
}
