import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
// import { emptyItem, getById, moveElement } from 'utils/builderUtils'
import {
  ACTION_REMOVE,
  ACTION_CREATE,
  ACTION_UPDATE
} from 'actions/workflowBuilderActions'

const generateEdge = (from, to) => {
  return {
    from,
    to
    // label: 'Execute in memory',
    // labelType: 'foreign',
    // meta: {
    //   description: 'I have no idea what to say about this so here is some text'
    // }

  }
}
const generateNode = (name, meta) => {
  return {
    id: name,
    label: name,
    meta,
    styles: {
      shape: {
        styles: {
          fill: '#ccc',
          stroke: '#000',
          fillOpacity: 1
        }
      },
      node: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }
      },
      label: {
        styles: { pointerEvents: 'none' }
      }
    }
  }
}

const reducer = produce((state, action) => {
  const { type, id, values } = action
  switch (type) {
    case ACTION_CREATE:
      return [...state, { id: uuid(), values }]
    case ACTION_REMOVE:
      return state.filter(item => item.id !== id)
    case ACTION_UPDATE: {
      for (const n of state.nodes) {
        if (n.id === id) {
          n.meta = values
        }
      }
      state.edges = state.edges.filter(e => e.from !== id)

      const { label, type, ...parameters } = values
      const { next, items } = parameters || {}

      const destinations = next ? [next] : items || []

      for (const d of destinations) {
        console.log(generateEdge(id, d))
        state.edges.push(generateEdge(id, d))
      }

      // state.nodes = []

      // state.nodes[i].meta = values
      // state.nodes[id] = values
      // return { ...state, ...action.values }
      // console.log(state.map(item =>
      //   item.id === id ? { ...item, values } : item
      // ))
      // return state.map(item =>
      //   item.id === id ? { ...item, values } : item
      // )
      // return state
    }
  }
})
export default reducer
