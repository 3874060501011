import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from 'elements/Form/Form'
import SubmitButton from 'elements/Button/SubmitButton'
import { useForm } from 'react-hook-form'
import { uid } from 'react-uid'
import {
  Box,
  Input,
  FormHelperText,
  FormErrorMessage,
  Stack,
  FormControl,
  FormLabel,
  Select
} from '@chakra-ui/core'

export default function FormBuilder ({ fields, values, id, doSubmit, doClose, options, ...rest }) {
  const defaultValues = {}

  for (const f of fields) {
    if (f.value) defaultValues[f.name] = f.value
  }
  const { handleSubmit, reset, errors, register, formState } = useForm({
    defaultValues: { ...defaultValues, ...values }
  })

  // Builder just passes new props if form is already opened and a new element selected so we need to reset
  useEffect(() => {
    reset(values)
  }, [reset, values])

  const onSubmit = useCallback(
    (values) => {
      doClose()
      doSubmit({ id, values })
    },
    [doClose, doSubmit, id]
  )
  return (<Box {...rest}>
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit) }>
      <Stack spacing={4}>
        { fields.map(field => {
          const { type, name, label, help, options } = field

          let input
          switch (type) {
            case 'text':
              input = <Input
                variant="filled"
                name={name}
                aria-describedby={`${name}-helper-text`}
                ref={register({ required: 'required field' })}
              />
              break
            case 'hidden':
              input = <Input
                type="text"
                variant="filled"
                name={name}
                aria-describedby={`${name}-helper-text`}
                ref={register({ required: 'required field' })}
              />
              break
            case 'select':
              input = <Select
                type="text"
                variant="filled"
                name={name}
                aria-describedby={`${name}-helper-text`}
                ref={register({ required: 'required field' })}
              >
                { options.map(({ key, value }) =>
                  <option key={uid(key)} value={key}>{value}</option>
                )}

              </Select>
              break
          }

          return <FormControl key={uid(name)} isInvalid={!!errors.name}>
            <FormLabel htmlFor={name}>{label || name}</FormLabel>
            { input }
            { help ? <FormHelperText id={`${name}-helper-text`}>
              {help }
            </FormHelperText> : null }
            <FormErrorMessage>
              {errors[name] && errors[name].message}
            </FormErrorMessage>
          </FormControl>
        })}

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>
      </Stack>
    </Form>
  </Box>
  )
}
FormBuilder.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string,
      name: PropTypes.string.isRequired,
      help: PropTypes.string
    })
  ),
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  doSubmit: PropTypes.func,
  doClose: PropTypes.func,
  options: PropTypes.object
}
