import React from 'react'
import PropTypes from 'prop-types'
import Editor from 'elements/CodeBuilder/Editor'
import FormBuilder from 'elements/FormBuilder/FormBuilder'
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader
} from '@chakra-ui/core'

export default function WorkflowModalEditor (props) {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const { state, onChange, onOpen, onClose, isOpen, name, selectedNode, formSchema, value } = props

  const { nodes, edges } = state
  let { title, description, fields } = formSchema

  // Resolve certain fields like next and items
  const destinations = nodes.filter(n => n.id !== selectedNode.id).map(n => ({ key: n.id, value: n.label }))

  fields = fields.map(f => {
    if (f.name === 'next') f.options = destinations
    return f
  })

  const { meta, id } = selectedNode || {}

  if (!selectedNode) return null
  return (
    <Modal onClose={onClose} size="xl" isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody >
          <ModalCloseButton />
          <Text>{description}</Text>
          <FormBuilder mt={10} fields={fields} id={id} values={meta} doClose={onClose} doSubmit={onChange}/>
        </ModalBody>
        {/* <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter> */}
      </ModalContent>
    </Modal>
  )
}

WorkflowModalEditor.defaultProps = {

}

WorkflowModalEditor.propTypes = {
  name: PropTypes.string,
  selectedNode: PropTypes.object,
  // value: PropTypes.any,
  formSchema: PropTypes.object,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  onDone: PropTypes.func,
  value: PropTypes.any

}
