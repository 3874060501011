import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  PseudoBox
} from '@chakra-ui/core'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
// import 'prismjs/themes/prism-twilight.css' // for dark mode
// import 'prismjs/themes/prism.css'
// import 'prismjs/components/prism-json'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'

export default function CodeEditor (props) {
  const { onChange, onBlur, value } = props

  // Count newlines and pad
  const lines = (value.match(/\n/g) || []).length + 2

  // Determine padding needed (length of line number length)
  const pad = String(lines).length

  //  Make sharp stop editor gradient background padded to fit font size/style
  const bg = `linear-gradient(90deg, #EDF2F7 ${20 + pad * 8}px, ${20 + pad * 8}px, #FFF 100%)`

  // Create content with all line numbers and newline them
  const lineNos = [...Array(lines).keys()].slice(1).join('\\00000a')

  // Style pseudo
  const before = {
    fontFamily: 'Inconsolata, monospace',
    paddingTop: '10px',
    content: `"${lineNos}"`,
    width: `${20 + pad * 8}px`,
    position: 'absolute',
    whiteSpace: 'pre',
    paddingRight: '10px',
    textAlign: 'right',
    opacity: 1
  }
  return (
    <PseudoBox h="100%"
      borderWidth={1} borderRadius="4px" position="relative" background={bg} _before={before}>
      <Editor
        width="100%"
        value={value}
        onValueChange={onChange}
        highlight={code =>
          highlight(code, languages.js)
        }
        padding={10}
        style={{
          fontFamily: 'Inconsolata, monospace',
          fontSize: 16,
          marginLeft: `${20 + pad * 8}px`
        }}
      />
    </PseudoBox>
  )
}

CodeEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
  // options: PropTypes.object
}
